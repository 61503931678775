<template>
  <div class="diamond-page ug" :class="$languageDir">
    <!-- 导航栏 -->
    <van-sticky>
      <van-nav-bar @click-right="handelBack">
        <template #title>
          <span :class="{ ug: language == 'ug' }">{{ $t('钻石') }}</span>
        </template>

        <template #right>
          <img class="back-icon" src="@/assets/icon_back.png" alt="" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="diamond-top">
      {{
        $t(
          '和心仪对象聊天需要消耗钻石，当您钻石不足时可以在此页面充值钻石，然后和您的心仪对象进行更深入的了解，首充还享受钻石赠送，名额有限，赶快充值吧~'
        )
      }}
    </div>
    <div class="diamond-info-wrap">
      <div class="go-list" @click="goRecordPage">
        {{ $t('收支记录') }}
      </div>
      <div class="diamond-info">
        <div class="diamond-info-title">{{ $t('钻石余额') }}</div>
        <div class="count">
          {{ diamond }}
          <span class="diamond-icon"></span>
        </div>
      </div>
    </div>
    <div class="diamond-list-wrap">
      <div
        :class="[
          'diamond-list-item',
          currentIndex === index && 'diamond-list-active'
        ]"
        v-for="(item, index) in ruleList"
        :key="item.id"
        @click="selectDiamond(index)"
      >
        <template v-if="item.giveDiamond">
          <div class="first-tip-scroll" >
            <p class="scroll-p">
              <span v-if="!index">{{ $t('绑定提醒') }}</span>
              <span v-if="!index">{{ $t('绑定提醒') }}</span>
              <span v-if="!index">{{ $t('绑定提醒') }}</span>
              <span v-if="!index">{{ $t('绑定提醒') }}</span>
              <span v-if="index ">{{ $t('首充特惠') }}</span>
              <span v-if="index ">{{ $t('首充特惠') }}</span>
              <span v-if="index ">{{ $t('首充特惠') }}</span>
              <span v-if="index ">{{ $t('首充特惠') }}</span>
            </p>
          </div>
          <!-- <div class="first-tip" v-else>{{ $t('首充特惠') }}</div> -->
        </template>
        <div class="item-top">
          <div class="item-icon"></div>
          <div class="item-first" v-if="item.giveDiamond">
            {{
              $t('送XX钻石', {
                num: item.giveDiamond
              })
            }}
          </div>
        </div>
        <div class="item-bottom">
          <div class="item-count">{{ item.diamond }}</div>
          <div class="item-money">
            <div>{{ item.currencySymbol }}</div>
            <div>{{ item.localMoney }}</div>
          </div>
        </div>
        <div class="item-check-icon" v-show="currentIndex === index"></div>
      </div>
    </div>
    <div class="payment-title">{{ $t('支付选择') }}</div>
    <div class="payment-wrap">
      <div v-for="(item, index) in codaPayWays" :key="index">
        <div
          v-if="item.payType == -1"
          class="payment-google-btn"
          @click="googlePay()"
        ></div>
        <div
          v-else-if="item.payType == -2"
          id="payment-paypal"
          class="payment-paypal-btn"
        ></div>
        <div
          v-else-if="item.payType == -3"
          id="payment-card"
          class="payment-paypal-btn"
        ></div>
        <div v-else class="coda-payment-btn" @click="clickCodaPay(item.id)">
          <img v-if="item.icon" class="icon-pay" :src="item.icon" />
          {{ item.payName }}
        </div>
      </div>
      <!-- <div class="coda-payment-btn" @click="goLocationPage">去本地调试</div> -->

      <!-- <google-pay-button
        environment="TEST"
        button-type="buy"
        button-color="white"
        button-radius="10"
        :paymentRequest.prop="paymentRequest"
        :clickCallback.prop="googlePayClick"
        @cancel="googlePayCancel"
        @loadpaymentdata="onLoadPaymentData"
        @error="googlePayError"
      ></google-pay-button> -->
    </div>
    <div class="help-wrap" @click="goFeedback">
      <span class="help-icon"></span>
      {{ $t('帮助') }}
    </div>
    <div class="retain-dialog-mask" v-if="isRetainDialog">
      <div class="retain-wrap">
        <div class="retain-title">{{ $t('提示') }}</div>
        <div class="retain-content">
          {{ $t('充值钻石可以和心仪对象深入聊天，您确定要放弃充值吗？') }}
        </div>
        <div class="retain-btn-wrap">
          <div class="retain-btn retain-btn-confirm" @click="handelBack">
            {{ $t('确定') }}
          </div>
          <div class="retain-btn" @click="isRetainDialog = false">
            {{ $t('取消') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import '@google-pay/button-element'
import {
  appFunc_callExit,
  appFunc_senToken,
  appFunc_callGooglePay,
  callAppFunc_navigateFeedBack,
  appFunc_RegisterFinishCb
} from '@/utils/bridge'
import { CommonModule } from '@/store/modules/common'
import {
  apiGetDiamondRuleList,
  apiReportdiamondStatistics,
  apiGetPayTypeConfig
} from '@/api'
import paypalPurchase from './mixins/paypalPurchase.js'
// import googlePurchase from './mixins/googlePurchase.js'
import payReport from './mixins/payReport.js'
import codaPurchase from './mixins/codaPurchase.js'

export default {
  mixins: [paypalPurchase, payReport, codaPurchase],
  data() {
    return {
      diamond: 0,
      ruleList: [],
      currentIndex: 0,
      isGoogleClick: true,
      isPaySuccess: false,
      isRetainDialog: false,
      hasRetainDialog: false,
      codaPayWays: [],
      payWaysDefault: [
        {
          payType: -1
        },
        {
          payType: -2
        },
        {
          payType: -3
        }
      ]
    }
  },
  computed: {
    language() {
      return this.$i18n.locale
    }
  },
  methods: {
    goLocationPage() {
      window.location.href = 'http://192.168.2.32:8090/#/diamond'
    },
    async getPayment() {
      const { code, data } = await apiGetPayTypeConfig()
      if (code == 1001 && data.length) {
        this.codaPayWays = data
      } else {
        this.codaPayWays = this.payWaysDefault
      }
      this.codaPayWays.forEach(item => {
        if (item.payType == -2) {
          this.payWays.push('paypal')
        }
        if (item.payType == -3) {
          this.payWays.push('card')
        }
      })
    },
    // 谷歌支付成功
    googlePaySuccess(data) {
      console.log(data)
      this.$toast({
        message: this.$t('支付成功'),
        duration: 5000
      })
      this.isPaySuccess = true
      // this.reportPaySuccess('google')
      this.getRuleList()
    },
    // 谷歌支付
    googlePay() {
      if (!this.isGoogleClick) {
        return
      }
      this.isGoogleClick = false
      let timer = setTimeout(() => {
        this.isGoogleClick = true
        clearTimeout(timer)
        timer = null
      }, 2000)
      this.reportPayClick('google')
      console.log(this.ruleList[this.currentIndex])
      appFunc_callGooglePay({
        pageform: this.$route.query.pageform || '',
        ...this.ruleList[this.currentIndex]
      })
      // appFunc_RegisterGooglePaySuccess(this.googlePaySuccess)
    },
    // 跳转
    goRecordPage() {
      this.$router.push({
        path: '/diamond-record',
        query: {
          language: this.language
        }
      })
    },
    // 切换钻石选择
    selectDiamond(index) {
      this.currentIndex = index
    },
    // 返回
    handelBack() {
      if (!this.isRetainDialog && !this.hasRetainDialog && !this.isPaySuccess) {
        this.isRetainDialog = true
        this.hasRetainDialog = true
        return
      }
      appFunc_callExit()
    },
    // 获取token
    async getToken() {
      const res = await appFunc_senToken()
      console.log(res, '/////////////')
      CommonModule.setUserId(res.id)
      CommonModule.setToken(res.tokenForJs)
      CommonModule.setUserPhone(res.mobile)
      CommonModule.setUserName(res.name)
    },
    // 获取金豆配置列表
    async getRuleList() {
      const { code, data } = await apiGetDiamondRuleList()
      console.log(code, data)
      if (code === 1001) {
        this.diamond = data.diamond
        this.ruleList = data.rechargeConfigList
      }
    },
    // 跳转帮助界面
    goFeedback() {
      callAppFunc_navigateFeedBack()
    }
  },
  async created() {
    // if (process.env.NODE_ENV !== 'development') {
    await this.getToken()
    // }
    this.getRuleList()
    // Initialize Firebase
    this.initFirebase()
    appFunc_RegisterFinishCb(this.googlePaySuccess)

    this.$nextTick(() => {
      console.log('执行')
      if (this.$route.query.transactionId) {
        this.codaPayNotify(this.$route.query)
      }
      this.report('diamondrechargepage')
      apiReportdiamondStatistics(this.$route.query.pageform)
    })
    await this.getPayment()
    this.initPaypal()
  },
  mounted() {
    // console.log('执行mounted')
    // 进入-金豆页
    // 挂载完成后，判断浏览器是否支持popstate--物理返回键
    if (window.history && window.history.pushState) {
      history.pushState(null, '', document.URL)
      window.addEventListener('popstate', this.handelBack, false)
    }
  },
  //页面销毁时，取消监听。否则其他vue路由页面也会被监听--物理返回键
  destroyed() {
    window.removeEventListener('popstate', this.handelBack, false)
  }
}
</script>

<style lang="scss" scoped>
@keyframes move {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-50%);
  }
}
.diamond-page {
  color: #181818;
  text-align: right;

  .diamond-top {
    background: rgba(255, 235, 60, 0.3);
    padding: 8px 14px;
    font-size: 11px;
  }
  .diamond-info-wrap {
    box-sizing: border-box;
    width: 347px;
    height: 81px;
    @include bg-image('diamond/diamond_info_bg');
    padding: 0 16px 0 20px;
    margin: 28px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    font-size: 16px;
  }
  .go-list {
    padding: 6px 16px;
    border-radius: 12px;
    border: 1px solid #ffffff;
    font-weight: 500;
    font-size: 14px;
  }
  .count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
    font-size: 26px;
  }
  .diamond-icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    @include bg-image('diamond/diamond_icon');
    margin-left: 6px;
  }
  .diamond-list-wrap {
    height: fit-content;
    margin-bottom: 28px;
    display: flex;
    flex-direction: row-reverse;
    overflow: auto;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 0;
      height: 0;
    }
  }
  .diamond-list-item {
    width: 107px;
    flex-shrink: 0;
    border: 1px solid #f2f2f2;
    border-radius: 20px;
    margin-right: 8px;
    position: relative;
    text-align: center;
    background-color: #fffde8;

    &:first-child {
      margin-right: 14px;
    }
    &:last-child {
      margin-left: 14px;
    }
    .first-tip,
    .first-tip-scroll {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 10px;
      color: #ffffff;
      border-radius: 58px 58px 58px 58px;
      height: 16px;
      line-height: 16px;
      padding: 0 6px;
      box-sizing: border-box;
    }
    .first-tip {
      width: 200%;
      height: 32px;
      line-height: 32px;
      transform-origin: right;
      background: #ffae03;
      font-size: 20px;
      transform: scale(0.5) translateY(-16px);
    }
    .first-tip-scroll {
      width: 100%;
      overflow: hidden;
      background: #e93125;
      display: flex;
    }
    .scroll-p {
      white-space: nowrap;
      animation-delay: 1s;
      animation: move linear 3s infinite;

      span {
        margin-right: 20px;
      }
    }
    .item-top {
      height: 83px;
      border-radius: 20px 20px 0px 0px;
      padding-top: 10px;
      background-color: #fff9c4;
      box-sizing: border-box;
    }
    .item-bottom {
      padding: 0 6px;
    }
    .item-icon {
      width: 50px;
      height: 50px;
      @include bg-image('diamond/diamond_item_icon');
      margin: auto;
    }
    .item-first {
      width: 101px;
      height: 18px;
      line-height: 18px;
      background: #ffeb3c;
      border-radius: 4px;
      margin: 7px auto 0;
      color: #e93125;
      font-size: 10px;
    }
    .item-count {
      font-size: 16px;
      font-weight: 500;
      margin-top: 8px;
    }
    .item-money {
      width: fit-content;
      padding: 4px 16px;
      background: #f0f0f0;
      border-radius: 10px;
      margin: 8px auto 10px;
    }
    .item-check-icon {
      width: 17px;
      height: 17px;
      @include bg-image('diamond/check');
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .diamond-list-active {
    border-color: #ffee5d;
    .item-top {
      background-color: #fff8b4;
    }
    .item-money {
      background: #fff8b7;
    }
  }
  .payment-title {
    font-size: 14px;
    padding: 0 14px;
  }
  .payment-wrap {
    box-sizing: border-box;
    padding: 14px;
    .payment-paypal-btn {
      margin-bottom: 10px;
    }
    .coda-payment-btn {
      width: 347px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      background: #f8e856;
      border-radius: 10px;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: LexendDeca-Bold;
    }
    .icon-pay {
      width: 60px;
      height: 25px;
      margin-right: 8px;
    }
    .payment-google-btn {
      width: 347px;
      height: 44px;
      background: #f8e856;
      border-radius: 10px;
      @include bg-image('diamond/icon_goolgle@2x');
      background-size: 106px 20px;
      background-position: center;
      margin-bottom: 12px;
    }
    // google-pay-button {
    //   width: 100%;
    //   // margin-bottom: 10px;
    // }
  }
  .help-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    .help-icon {
      width: 23px;
      height: 23px;
      margin-right: 5px;
      @include bg-image('diamond/icon_bj');
    }
  }
}
.retain-dialog-mask {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .retain-wrap {
    box-sizing: border-box;
    width: 322px;
    font-size: 16px;
    text-align: center;
    color: #181818;
    background-color: #fff;
    border-radius: 20px;
  }
  .retain-title {
    font-size: 18px;
    padding: 20px 0 0;
  }
  .retain-content {
    font-size: 14px;
    margin: 12px auto 18px;
    padding: 0 28px;
  }
  .retain-btn-wrap {
    display: flex;
    border-top: 1px solid #f6f7f9;
  }
  .retain-btn {
    flex: 1;
    padding: 14px 0;
  }
  .retain-btn-confirm {
    border-right: 1px solid #f6f7f9;
    color: rgba(24, 24, 24, 0.6);
  }
}
</style>
<style lang="scss">
.diamond-page {
  .payment-wrap {
    .gpay-card-info-container {
      width: 100%;
      outline: none;
    }
    .gpay-card-info-container,
    .gpay-card-info-animation-container,
    .gpay-card-info-container.white.hover,
    .gpay-card-info-animation-container.white.hover {
      background-color: #f8e856;
    }
    .gpay-card-info-container.white.focus {
      outline: none;
    }
  }
}
.language-left {
  &.diamond-page {
    text-align: left;
    .diamond-info-title {
      text-align: right;
    }
    .diamond-list-wrap {
      flex-direction: row;
    }
    .diamond-list-item {
      &:first-child {
        margin-left: 14px;
      }
      &:last-child {
        margin-right: 14px;
        margin-left: 0;
      }
    }
  }
}
</style>
